import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  MenuItem,
  Typography,
  Grid,
} from "@mui/material";
// import Grid from "@mui/system/Grid";
import React, { useEffect, useState } from "react";
import { Images } from "../../helpers/Images";
import { useNavigate } from "react-router-dom";
import CategoriesCarousel from "../../Components/organisms/Home/CategoriesCarousel/CategoriesCarousel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./TrainersAcc.css";
import TextFieldInput from "../../Components/atoms/Inputs/TextField/TextFieldInput";
import { useForm } from "@formspree/react";
import useFetch from "../../Hooks/useFetch";
import { countriesURL } from "../../Hooks/URL";
import { toast } from "react-toastify";
import TextArea from "../../Components/atoms/TextArea/TextArea";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import TaskIcon from "@mui/icons-material/Task";
import StarsIcon from "@mui/icons-material/Stars";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import QuizIcon from "@mui/icons-material/Quiz";
import MergeIcon from "@mui/icons-material/Merge";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Facebook } from "@mui/icons-material";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import WorkIcon from "@mui/icons-material/Work";
import ReactCountryFlag from "react-country-flag";

const TrainersAccTemplate = () => {
  const navigate = useNavigate();
  const [state, handleSubmit] = useForm("mnnjdgqw");
  const [Name, setName] = useState("");
  const [Organization, setOrganization] = useState("");
  const [Country, setCountry] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Type, setType] = useState("Trainer");

  const Trainers = [
    {
      photo: Images.abdelrahman,
      name: "Abdelrahman Nazim",
      title: "Programming Mentor/Developer",
      whatsapp: "https://wa.me/+201016203200",
      linkedin: "https://www.linkedin.com/in/abdelrahman-nazem/",
      email: "abdelrahmankhalednazim@gmail.com",
      brief:
        "Abdelrahman Nazim, a certified coding mentor specializing in HTML, CSS, JavaScript, Scratch, PictoBlox, and AI at Mawaheb Academy. Passionate about teaching, innovation, and inspiring future coders.",
      location: "Suez/Cairo - Egypt",
      facebook: "https://www.facebook.com/profile.php?id=100005434277589",
      flag: "eg",
    },
    {
      photo: Images.hazem,
      name: "Hazem Mohamed",
      title: "Python & AI Developer",
      whatsapp: "https://wa.me/+201121029029",
      linkedin: "https://www.linkedin.com/in/hazem-mohamed-7ba928220/",
      email: "info2hazemmohamed@gmail.com",
      brief:
        "Hazem Mohamed, a certified Microsoft Fabric Analytics Engineer, and an AI & coding Instructor at Mawaheb Academy. He is experienced in machine learning and analytics using tools as Python, SQL, Power BI, and SAS.",
      location: "Cairo - Egypt",
      facebook: "https://www.facebook.com/profile.php?id=100003873443878",
      flag: "eg",
    },
    {
      photo: Images.sajda,
      name: "Sajeda Yasser",
      title: "Team Leader/STEM Instructor",
      whatsapp: "https://wa.me/+970594958002",
      linkedin: "https://www.linkedin.com/company/purposeedu/",
      email: "Sajeda@purpose.ps",
      brief:
        " I am Eng. Sajeda Yasser from Ramallah, a robotics, programming, and AI trainer for kids and youth. With 6 years of experience, I train trainers at Purpose Academy and have achieved top ranks in local and global competitions.",
      location: "Ramallah - Palestine",
      flag: "ps",
    },
  ];

  const { data: countriesList, loading: countriesLoading } =
    useFetch(countriesURL);

  useEffect(() => {
    if (state.succeeded) {
      setName("");
      setOrganization("");
      setEmail("");
      setCountry("");
      setPhone("");
      setType("Trainer");
      toast.success(
        "Thank you for filling the form! We  will get back to you as soon as possible.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: { backgroundColor: "#6e1010" },
        }
      );
    }
  }, [state.succeeded]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "20px 20px",
            sm: "20px 60px",
            lg: "30px 120px",
            xl: "75px 120px",
          },
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${Images.back6})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "50px" },
            fontWeight: "800",
            marginTop: "5px",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          Become A Certified Trainer
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <a href="#form">
            <Button
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "16px",
                marginTop: "0",
                fontWeight: "600",
                backgroundColor: "secondary.main",
                color: "primary.main",
              }}
            >
              Apply for Certification
            </Button>
          </a>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h5"}
          sx={{
            textAlign: { xs: "center", md: "start" },
            fontSize: { xs: "25px", sm: "34px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Empowering Educators in Coding
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "150px", md: "350px" },
            width: "100%",
            backgroundColor: "primary.light",
            margin: { xs: "5px auto 0", md: "5px 0 0 0" },
          }}
        />
        <br />
        <Typography
          variant={"h3"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "16px", sm: "18px" },
            fontWeight: "400",
            color: "primary.darker",
          }}
        >
          The Trainer Certification program recognizes individuals who
          demonstrate exceptional skills and a commitment to teaching coding and
          technology. This certification establishes you as a leader in the
          field, capable of inspiring and guiding the next generation of
          innovators.
        </Typography>

        <Typography
          id={"part"}
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "22px", sm: "35px" },
            fontWeight: "500",
            color: "primary.darker",
            marginTop: "40px",
          }}
        >
          Meet Our Certified Trainers
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: { xs: "150px", sm: "200px" },
            backgroundColor: "primary.light",
            margin: "5px auto 30px",
          }}
        />
        <Grid
          container
          rowSpacing={{ xs: 4, md: 6 }}
          columnSpacing={{ xs: 2, md: 4 }}
        >
          {Trainers?.map((item) => (
            <Grid item xs={6} md={3}>
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                  height: { xs: "200px", md: "300px" },
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url("${item?.photo}")`,
                }}
              >
                <ReactCountryFlag
                  svg
                  countryCode={item?.flag}
                  style={{
                    width: "40px",
                    height: "auto",
                    position: "absolute",
                    top: "5px",
                    left: "5px",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    "&:hover": {
                      opacity: "1",
                    },
                  }}
                >
                  <Typography
                    variant={"h4"}
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "14px", sm: "18px" },
                      fontWeight: "500",
                      color: "White",
                      marginTop: "10px",
                    }}
                  >
                    About {item?.name}
                  </Typography>

                  <Typography
                    variant={"p"}
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "10px", sm: "14px" },
                      fontWeight: "500",
                      color: "White",
                      marginTop: "10px",
                    }}
                  >
                    {item?.brief}
                  </Typography>
                  <Typography
                    variant={"p"}
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "10px", sm: "14px" },
                      fontWeight: "500",
                      color: "White",
                      marginTop: "10px",
                    }}
                  >
                    Location: {item?.location}
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant={"h4"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "16px", sm: "22px" },
                  fontWeight: "500",
                  color: "primary.darker",
                  marginTop: "10px",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                {item?.name}
              </Typography>
              <Typography
                variant={"h5"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "300",
                  color: "primary.darker",
                }}
              >
                {item?.title}
              </Typography>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex !important",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: { xs: "0 auto", md: "5px auto 0" },
                }}
              >
                {item?.facebook ? (
                  <a target="_blank" href={item?.facebook} className="accIcon">
                    <Facebook className="accInsideIcon" />
                  </a>
                ) : (
                  ""
                )}
                {item?.whatsapp ? (
                  <a target="_blank" href={item?.whatsapp} className="accIcon">
                    <WhatsAppIcon className="accInsideIcon" />
                  </a>
                ) : (
                  ""
                )}
                {item?.linkedin ? (
                  <a target="_blank" href={item?.linkedin} className="accIcon">
                    <LinkedInIcon className="accInsideIcon" />
                  </a>
                ) : (
                  ""
                )}
                {item?.email ? (
                  <a
                    target="_blank"
                    href={`mailto:${item?.email}`}
                    className="accIcon"
                  >
                    <EmailIcon className="accInsideIcon" />
                  </a>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            color: "primary.darker",
            fontSize: "30px",
            marginTop: "60px",
          }}
        >
          Why Become an Certified Trainer?
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: "150px",
            backgroundColor: "primary.light",
            margin: "10px auto",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            margin: "20px auto 20px",
            maxWidth: "1500px",
            textAlign: "20px",
          }}
        >
          <ul className="WhyList WhyListBig">
            <li>
              <WorkspacePremiumIcon className="listTick" />
              Stand out as a certified coding educator and leader in technology
              education
            </li>
            <li>
              <EmojiEventsIcon className="listTick" />
              Showcase your teaching expertise with our official certification
              badge
            </li>
            <li>
              <MergeIcon className="listTick" />
              Unlock pathways to collaborate on Code Challenge initiatives
            </li>
            <li>
              <TaskIcon className="listTick" />
              Access exclusive resources and continuous learning opportunities
            </li>
            <li>
              <CardMembershipIcon className="listTick" />
              Get prominently featured on our platform to establish strong
              credibility
            </li>
            <li>
              <WorkIcon className="listTick" />
              Unlock new career opportunities and significantly enhance your
              visibility
            </li>
          </ul>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px",
            sm: "50px 60px",
            lg: "50px 120px",
            xl: "150px 120px",
          },
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(${Images.services})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "30px", sm: "40px" },
            fontWeight: "800",
            marginTop: "5px",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          Empowering Excellence in Training and Education
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "16px", sm: "20px" },
            fontWeight: "500",
            maxWidth: "1100px",
            margin: "0 auto",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          Certification not only validates your expertise but also connects you
          with a network of like-minded professionals and opportunities to make
          a greater difference in the tech education landscape.
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <a href="#form">
            <Button
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "16px",
                marginTop: "0",
                fontWeight: "600",
                backgroundColor: "secondary.main",
                color: "primary.main",
              }}
            >
              Apply for Certification
            </Button>
          </a>
        </Box>
      </Box>
      {/* <CategoriesCarousel /> */}
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px 40px",
            sm: "50px 60px 50px",
            lg: "50px 120px 50px",
          },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            color: "primary.darker",
            fontSize: "30px",
          }}
        >
          Steps To Get Certified
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: "150px",
            backgroundColor: "primary.light",
            margin: "6px auto",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            margin: "20px auto 60px",
            maxWidth: "1500px",
            textAlign: "20px",
          }}
        >
          <ul className="WhyList WhyListQuar">
            <li>
              <span className="listTickNumber">1</span>
              Fill out the form, and we will contact you
            </li>
            <li>
              <span className="listTickNumber">2</span>
              Prepare with the "Code to Lead" Course (Optional)
            </li>
            <li>
              <span className="listTickNumber">3</span>
              Pass the Official Exam with a score of at least 75%
            </li>
            <li>
              <span className="listTickNumber">4</span>
              Join the Certified Network as a Certified Trainer
            </li>
          </ul>
        </Box>
        <Typography
          id={"part"}
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "30px", sm: "35px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Apply for Certification
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: { xs: "150px", sm: "200px" },
            backgroundColor: "primary.light",
            margin: "5px auto 10px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            width: "100%",
            maxWidth: "750px",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "30px",
          }}
        >
          Ready to take your impact to the next level? Apply for Code Challenge
          Certification and become a beacon of innovation and learning in your
          community!
        </Typography>
        <form
          onSubmit={handleSubmit}
          id="form"
          style={{ maxWidth: "800px", margin: "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
              columnGap: "20px",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="name"
                  type="text"
                  placeholder={"Enter your full name.."}
                  className="Input"
                  label="Full Name"
                  id={"input"}
                  newValue={Name}
                  change={(e) => setName(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="organization"
                  type="text"
                  placeholder={"Enter organization name.."}
                  className="Input"
                  label="Organization Name"
                  id={"input"}
                  newValue={Organization}
                  change={(e) => setOrganization(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="email"
                  type="text"
                  placeholder={"Enter your email.."}
                  className="Input"
                  label="Email"
                  newValue={Email}
                  change={(e) => setEmail(e.target.value)}
                />
              </div>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="number"
                  type="text"
                  placeholder={"Enter your contact number.."}
                  className="Input"
                  label="Contact Number"
                  newValue={Phone}
                  change={(e) => setPhone(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  select
                  label="Country"
                  placeholder={"Choose your country"}
                  name={"country"}
                  fullWidth
                  className={`Input`}
                  variant="outlined"
                  newValue={Country}
                  change={(e) => setCountry(e.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="" disabled>
                    Choose Country
                  </MenuItem>
                  {countriesList?.data?.map((item, i) => (
                    <MenuItem key={i} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </TextFieldInput>
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  select
                  label="Accreditation For"
                  name={"type"}
                  fullWidth
                  className={`Input`}
                  variant="outlined"
                  newValue={Type}
                  change={(e) => setType(e.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="" disabled>
                    Choose Accreditation Type
                  </MenuItem>
                  <MenuItem key={1} value={"Trainer"}>
                    Trainer
                  </MenuItem>
                  <MenuItem key={2} value={"Organization"}>
                    Organization
                  </MenuItem>
                </TextFieldInput>
              </div>
            </Box>
          </Box>
          {/* <div style={{ margin: "10px auto 10px" }}>
            <TextArea
              Required
              name="text"
              placeholder={"Any comments.."}
              className="Input"
              label="Comments/Queries"
              newValue={Message}
              change={(e) => setMessage(e.target.value)}
            />
          </div> */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              disabled={state.submitting}
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "14px",
                marginTop: "10px",
                fontWeight: "600",
                backgroundColor: "primary.main",
                color: "secondary.main",
              }}
              // onClick={() => navigate("/firststage")}
            >
              Apply Now
            </Button>
          </div>
        </form>
      </Box>
    </>
  );
};

export default TrainersAccTemplate;
