import {
  Box,
  Button,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./FirstStageConfirm.css";
import * as Yup from "yup";
import { Formik } from "formik";
import FormInput from "../../../Components/atoms/FormInput/FormInput";
import TextFieldInput from "../../../Components/atoms/Inputs/TextField/TextFieldInput";
import { PhoneInput } from "react-international-phone";
import phone from "phone";
import "react-international-phone/style.css";
import TeamCard from "../../../Components/molecules/Cards/TeamCard/TeamCard";
import WhiteModal from "../../../Components/molecules/Modal/Modal";
import { PulseLoader } from "react-spinners";
import { arrayOfAcademies } from "../../../helpers/Arrays";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { BaseURL, applicationURL } from "../../../Hooks/URL";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Images } from "../../../helpers/Images";

const FirstStageConfirmTemplate = ({ application }) => {
  // const handleCopy = () => {
  //   try {
  //     document.execCommand("copy");
  //   } catch (error) {
  //     console.error("Unable to copy to clipboard:", error);
  //   }
  // };
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CheckCircleOutlineIcon className="successIcon" />
      </div>
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "40px", sm: "55px" },
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        Thank you for your registration!
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "400",
          color: "primary.darker",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        You’ve successfully registered in our system, and we’re excited to have
        you on board. We’re here to support you as you take the next steps, so
        don’t hesitate to reach out if you need any help. Welcome aboard!
      </Typography>
      <div className="appSummary2">
        <h3 style={{ fontWeight: "500", textAlign: "center" }}>
          Your account code is:{" "}
          <span style={{ fontWeight: "700" }}>
            {application?.loginData?.accountCode}
          </span>
        </h3>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            marginBottom: "40px",
          }}
        >
          <a
            // href="https://registrationstaging.codechallenge-championship.org/login"
            href="https://registration.codechallenge-championship.org/login"
            target="_blank"
          >
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "150px", margin: "0 5px" }}
            >
              Log in to your account
            </Button>
          </a>
        </div>
        <h2>What to Do Next:</h2>
        <ol className="confirmList" style={{ fontSize: "20px" }}>
          <li>
            Click the{" "}
            <span style={{ fontWeight: "700" }}>Log in to your account</span>{" "}
            button above.
          </li>
          <li>
            Use your <span style={{ fontWeight: "700" }}>Account Code</span> and
            the password you created during registration to log in.
          </li>
          <li>
            Complete registration by adding your teams, members, and coaches,
            and complete the payment process to finalize your registration.
          </li>
        </ol>
        <p>
          - Make sure to save your account details securely! You’ll need them to
          log in and access your account later.
        </p>
      </div>
    </Box>
  );
};

export default FirstStageConfirmTemplate;
