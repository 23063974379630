/* -- -- -- Logo -- -- -- */

import NavBack from "../assets/nav.png";
import govern from "../assets/city.png";
import team from "../assets/team.png";
import coach from "../assets/leadership.png";
import category from "../assets/category.png";
import academy from "../assets/headquarter.png";
import student from "../assets/man.png";
import menu from "../assets/menu.png";
import nextArrow from "../assets/next.png";
import prevArrow from "../assets/previous.png";
import code from "../assets/code.webp";
import call from "../assets/phone-call.png";
import chat from "../assets/chatting.png";
import quote from "../assets/quote.png";
import codeLogo from "../assets/CodeLogo.png";
import codeLogoHor from "../assets/CC.png";
import codeWhite from "../assets/CC-white.png";
import back2 from "../assets/back2.jpg";
import back3 from "../assets/back3.jpg";
import back4 from "../assets/back4.jpg";
import back5 from "../assets/back5.jpg";
import back6 from "../assets/back6.jpg";
import code1 from "../assets/code1.webp";
import code2 from "../assets/code2.webp";
import code3 from "../assets/code3.webp";
import code4 from "../assets/code4.webp";
import cover from "../assets/Cover.png";
import cover2 from "../assets/Cover2.png";
import BigLogo from "../assets/BigCodeLogo.png";
import sponsor1 from "../assets/Sponsers/sponser (1).png";
import sponsor2 from "../assets/Sponsers/sponser (2).png";
import sponsor3 from "../assets/Sponsers/sponser (3).png";
import sponsor4 from "../assets/Sponsers/sponser (4).png";
import sponsor5 from "../assets/Sponsers/sponser (5).png";
import sponsor6 from "../assets/Sponsers/sponser (6).png";
import sponsor7 from "../assets/Sponsers/sponser (7).png";
import gold from "../assets/gold.jpg";
import cup from "../assets/cup.png";
import prize1 from "../assets/prizes/prize (1).jpg";
import prize2 from "../assets/prizes/prize (2).jpg";
import prize3 from "../assets/prizes/prize (3).jpg";
import prize4 from "../assets/prizes/prize (4).jpg";
import prize5 from "../assets/prizes/prize (5).jpg";
import prize6 from "../assets/prizes/prize (6).jpg";
import services from "../assets/ser.jpg";
import pulsonic from "../assets/Full-White.png";
import map from "../assets/map.jpg";
import hand from "../assets/handshake.jpg";
import hand1 from "../assets/shaking1.jpg";
import hand2 from "../assets/shaking2.jpg";
import abdelrahman from "../assets/trainers/profile.webp";
import hazem from "../assets/trainers/hazem.webp";
import sajda from "../assets/trainers/sajda.webp";
import videoBack from "../assets/video/Code1.webm";

import mawaheb from "../assets/organizations/mawaheb.webp";
import purpose from "../assets/organizations/purpose.webp";
import tamayoz from "../assets/organizations/Tamayoz Academy.webp";
import child from "../assets/organizations/Child.webp";
import innova from "../assets/organizations/innova.webp";
import innovation from "../assets/organizations/Innovation.webp";
import inventa from "../assets/organizations/INVENTA Academy.webp";
import kids from "../assets/organizations/Kids.webp";
import land from "../assets/organizations/Land.webp";
import lc from "../assets/organizations/LC.webp";
import light from "../assets/organizations/Light.webp";
import little from "../assets/organizations/little.webp";
import logic from "../assets/organizations/Logic.webp";
import mindCraft from "../assets/organizations/MindCraft.webp";
import sts from "../assets/organizations/sts.webp";
import techno from "../assets/organizations/Techno.webp";
import technology from "../assets/organizations/technology.webp";
import techware from "../assets/organizations/techware.webp";
import willy from "../assets/organizations/Willy.webp";
import scrBackW from "../assets/S-R.png";
import webBackW from "../assets/H-R.png";
import pythonBackW from "../assets/P-R.png";
import aiBackW from "../assets/A-R.png";
import scrBack from "../assets/S-W.png";
import webBack from "../assets/H-W.png";
import pythonBack from "../assets/P-W.png";
import aiBack from "../assets/A-W.png";
import LogoHor from "../assets/Code H Logo.webp";

export const Videos = {
  videoBack,
};

export const Images = {
  cup,
  map,
  scrBack,
  LogoHor,
  webBack,
  pythonBack,
  aiBack,
  scrBackW,
  webBackW,
  pythonBackW,
  aiBackW,
  tamayoz,
  child,
  innova,
  innovation,
  inventa,
  kids,
  land,
  lc,
  light,
  little,
  logic,
  mindCraft,
  sts,
  techno,
  technology,
  techware,
  willy,
  hand,
  purpose,
  mawaheb,
  abdelrahman,
  hazem,
  sajda,
  hand1,
  hand2,
  gold,
  code,
  call,
  chat,
  codeWhite,
  NavBack,
  govern,
  team,
  coach,
  academy,
  category,
  student,
  menu,
  nextArrow,
  prevArrow,
  quote,
  codeLogo,
  codeLogoHor,
  BigLogo,
  back2,
  back3,
  back4,
  back5,
  back6,
  code1,
  code2,
  code3,
  code4,
  cover,
  cover2,
  sponsor1,
  sponsor2,
  sponsor3,
  sponsor4,
  sponsor5,
  sponsor6,
  sponsor7,
  prize1,
  prize2,
  prize3,
  prize4,
  prize5,
  prize6,
  services,
  pulsonic,
};
