import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./FirstStage.css";
import * as Yup from "yup";
import { Formik } from "formik";
import FormInput from "../../../Components/atoms/FormInput/FormInput";
import TextFieldInput from "../../../Components/atoms/Inputs/TextField/TextFieldInput";
import { PhoneInput } from "react-international-phone";
import phone from "phone";
import "react-international-phone/style.css";
import TeamCard from "../../../Components/molecules/Cards/TeamCard/TeamCard";
import WhiteModal from "../../../Components/molecules/Modal/Modal";
import { PulseLoader } from "react-spinners";
import { arrayOfAcademies } from "../../../helpers/Arrays";
import { Images } from "../../../helpers/Images";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const PhaseOneIndvidualSchema = Yup.object().shape({
  admin_name: Yup.string().required("Admin Name required !!"),
  admin_email: Yup.string()
    .email("Invalid email !!")
    .required("Admin Email required !!"),
  country: Yup.string().required("Country required !!"),
  gov: Yup.string().required("Governorate required !!"),
  referralSource: Yup.string().required("Choose an option !!"),
  password: Yup.string()
    .min(8, "Password is too short !!")
    .test(
      "isValidPass",
      "Password must contain at least 1 number, 1 uppercase, 1 lowercase, 1 special character !!",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSymbol = /[!@#%&]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 4;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    )
    .required("Password required !!"),
  confirmPassword: Yup.string()
    .min(8, "Password is too short !!")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password required !!"),
});

const PhaseOneOrganizationSchema = Yup.object().shape({
  admin_name: Yup.string().required("Admin Name required !!"),
  admin_email: Yup.string()
    .email("Invalid email !!")
    .required("Admin Email required !!"),
  referralSource: Yup.string().required("Choose an option !!"),
  organization_id: Yup.string().required("Organization required !!"),
  organization_name: Yup.string().when("organization_id", (organization_id) => {
    if (organization_id == -1) {
      return Yup.string().required("Organization Name required !!");
    }
  }),
  country: Yup.string().required("Branch Country required !!"),
  gov: Yup.string().required("Branch Governorate required !!"),
  address: Yup.string().required("Branch Street required !!"),
  password: Yup.string()
    .min(8, "Password is too short !!")
    .test(
      "isValidPass",
      "Password must contain at least 1 number, 1 uppercase, 1 lowercase, 1 special character !!",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSymbol = /[!@#%&]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 4;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    )
    .required("Password required !!"),
  confirmPassword: Yup.string()
    .min(8, "Password is too short !!")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password required !!"),
});

const FirstStageTemplate = ({
  newObject,
  setNewObject,
  setGoSave,
  confirmOpen,
  setConfirmOpen,
  feesOpen,
  setFeesOpen,
  setFinalSave,
  addLoading,
  countriesList,
  organizationsList,
  newObjectConfirm,
  setCountry,
  cities,
  FinalLoading,
  userType,
  handleType,
  promocode,
  setPromocode,
}) => {
  const navigate = useNavigate();
  const [Phone, setPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(null);
  const [PhoneTouched, setPhoneTouched] = useState(false);
  const [PhoneOrg, setPhoneOrg] = useState("");
  const [PhoneOrgError, setPhoneOrgError] = useState(null);
  const [PhoneOrgTouched, setPhoneOrgTouched] = useState(false);

  useEffect(() => {
    if (
      !phone(Phone)?.isValid ||
      (Phone?.length < 13 && phone(Phone)?.countryCode == "+20")
    )
      setPhoneError("Valid Phone Number Required!!");
    else setPhoneError("");
  }, [Phone]);

  useEffect(() => {
    if (
      !phone(PhoneOrg)?.isValid ||
      (PhoneOrg?.length < 13 && phone(PhoneOrg)?.countryCode == "+20")
    )
      setPhoneOrgError("Valid Phone Number Required!!");
    else setPhoneOrgError("");
  }, [PhoneOrg]);

  return (
    <>
      {false ? (
        <>
          <Box
            sx={{
              width: "100%",
              padding: {
                xs: "70px 20px",
                sm: "70px 60px",
                lg: "70px 120px",
                xl: "150px 120px",
              },
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${Images.back2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              minHeight: "500px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "35px", sm: "40px" },
                fontWeight: "500",
                color: "secondary.main",
              }}
            >
              Registration 2024
            </Typography>
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "60px", sm: "70px" },
                fontWeight: "700",
                color: "secondary.main",
              }}
            >
              COMING SOON
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "400",
                color: "secondary.main",
                marginTop: "0px",
              }}
            >
              Are you ready to be the champion this year ?
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
              maxWidth: "1500px",
              margin: "auto",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "40px", sm: "55px" },
                fontWeight: "500",
                color: "primary.darker",
              }}
            >
              Register to Code Challenge System
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "400",
                color: "primary.darker",
                margin: "auto",
                marginTop: "20px",
                maxWidth: "800px",
              }}
            >
              Please complete the form below to create a new account in code
              challenge registration system. <br />
              Already registered?{" "}
              <a
                // href="https://registrationstaging.codechallenge-championship.org/login"
                href="https://registration.codechallenge-championship.org/login"
                target="_blank"
              >
                Log in to your account
              </a>
            </Typography>
            <Box
              sx={{
                maxWidth: "500px",
                margin: "20px auto 0",
              }}
            >
              <ToggleButtonGroup
                color="primary"
                value={userType}
                exclusive
                onChange={handleType}
                fullWidth
                sx={{ flexWrap: { xs: "wrap", sm: "nowrap" } }}
              >
                <ToggleButton value="organization">
                  organization/academies
                </ToggleButton>
                <ToggleButton value="individual">individual/team</ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {/* <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "100px", md: "250px" },
                width: "100%",
                backgroundColor: "primary.main",
                margin: "10px auto 20px auto",
              }}
            /> */}
            <Formik
              initialValues={{
                admin_name: "",
                admin_email: "",
                organization_id: "",
                organization_name: "",
                country: 42,
                gov: "",
                address: "",
                password: "",
                confirmPassword: "",
                referralSource: "",
              }}
              validationSchema={
                userType == "organization"
                  ? PhaseOneOrganizationSchema
                  : PhaseOneIndvidualSchema
              }
              onSubmit={(values) => {
                if (userType == "organization") {
                  if (!PhoneError && !PhoneOrgError) {
                    setNewObject({
                      admin: {
                        name: values?.admin_name,
                        email: values?.admin_email,
                        contactNumber: Phone,
                      },
                      promoCode: promocode ?? undefined,
                      referralSource: values?.referralSource,
                      type: "organization",
                      organization:
                        values?.organization_id == -1
                          ? {
                              new: true,
                              name: values?.organization_name,
                              branch: {
                                address: values?.address,
                                cityId: values?.gov,
                                contactNumber: PhoneOrg,
                              },
                            }
                          : {
                              new: false,
                              id: values?.organization_id,
                              branch: {
                                address: values?.address,
                                cityId: values?.gov,
                                contactNumber: PhoneOrg,
                              },
                            },
                      accountPassword: {
                        password: values?.password,
                        confirmPassword: values?.confirmPassword,
                      },
                    });
                    setGoSave(true);
                  }
                } else {
                  if (!PhoneError) {
                    setNewObject({
                      admin: {
                        name: values?.admin_name,
                        email: values?.admin_email,
                        contactNumber: Phone,
                      },
                      promoCode: promocode ?? undefined,
                      referralSource: values?.referralSource,
                      type: "personal",
                      personalAccountInfo: {
                        cityId: values?.gov,
                      },
                      accountPassword: {
                        password: values?.password,
                        confirmPassword: values?.confirmPassword,
                      },
                    });
                    setGoSave(true);
                  }
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form
                  className="FormContainer"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: { xs: "column", md: "row" },
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "14px",
                        margin: "5px",
                        fontWeight: "600",
                        backgroundColor: "primary.main",
                        margin: "5px",
                        width: "fit-content",
                      }}
                      onClick={() => {
                        setFeesOpen(true);
                      }}
                    >
                      Registration Details
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "14px",
                        margin: "5px",
                        fontWeight: "600",
                        backgroundColor: "primary.main",
                        margin: "5px",
                        width: "fit-content",
                      }}
                      onClick={() => {
                        navigate("/check");
                      }}
                    >
                      Check your application
                    </Button>
                  </Box> */}
                  {/* {userType == "organization" ? <h2>1 - Admin</h2> : ""} */}

                  <div
                    className="FormContainerBox"
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <div>
                      <TextFieldInput
                        name="admin_name"
                        type="text"
                        placeholder={"Enter your name"}
                        className="Input"
                        label={
                          userType == "organization"
                            ? "Admin Name"
                            : "Team Leader Name"
                        }
                        change={handleChange}
                        blur={handleBlur}
                        newValue={values.admin_name}
                      />
                      {errors.admin_name &&
                        touched.admin_name &&
                        errors.admin_name && (
                          <p className="error">
                            {errors.admin_name &&
                              touched.admin_name &&
                              errors.admin_name}
                          </p>
                        )}
                    </div>
                    <div>
                      <TextFieldInput
                        name="admin_email"
                        type="email"
                        placeholder={"Enter your email"}
                        className="Input"
                        label={
                          userType == "organization"
                            ? "Admin Email"
                            : "Team Leader Email"
                        }
                        change={handleChange}
                        blur={handleBlur}
                        newValue={values.admin_email}
                      />
                      {errors.admin_email &&
                        touched.admin_email &&
                        errors.admin_email && (
                          <p className="error">
                            {errors.admin_email &&
                              touched.admin_email &&
                              errors.admin_email}
                          </p>
                        )}
                    </div>
                    <div>
                      <TextFieldInput
                        name="password"
                        type="password"
                        placeholder={"Enter a new password"}
                        className="Input"
                        label={"Create A New Password"}
                        change={handleChange}
                        blur={handleBlur}
                        newValue={values.password}
                      />
                      {errors.password &&
                        touched.password &&
                        errors.password && (
                          <p className="error">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>
                        )}
                    </div>
                    <div>
                      <TextFieldInput
                        name="confirmPassword"
                        type="password"
                        placeholder={"Rewrite your password"}
                        className="Input"
                        label={"Confirm Your Password"}
                        change={handleChange}
                        blur={handleBlur}
                        newValue={values.confirmPassword}
                      />
                      {errors.confirmPassword &&
                        touched.confirmPassword &&
                        errors.confirmPassword && (
                          <p className="error">
                            {errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword}
                          </p>
                        )}
                    </div>
                    <div style={{ position: "relative" }}>
                      <label className="labelPhone">
                        {userType == "organization"
                          ? "Admin Phone Number"
                          : "Team Leader Phone Number"}
                        *
                      </label>
                      <PhoneInput
                        label={"Enter Admin Phone Number"}
                        className="textfield Input phoneInput"
                        value={Phone}
                        defaultCountry="eg"
                        onChange={(e) => {
                          setPhone(e);
                        }}
                      />
                      {PhoneError && PhoneTouched ? (
                        <p className="error">{PhoneError}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    {userType == "individual" ? (
                      <>
                        <div>
                          <TextFieldInput
                            select
                            label="Country"
                            newValue={values.country}
                            placeholder={"Choose country"}
                            name={"country"}
                            change={(newValue) => {
                              handleChange(newValue);
                              setCountry(newValue);
                              handleChange({
                                target: { name: "gov", value: "" },
                              });
                            }}
                            blur={handleBlur}
                            fullWidth
                            className={`Input`}
                            variant="outlined"
                            SelectProps={{
                              displayEmpty: true,
                            }}
                          >
                            <MenuItem value="" className="" disabled>
                              Choose Country
                            </MenuItem>
                            {countriesList?.map((item) => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </TextFieldInput>
                          {errors.country &&
                            touched.country &&
                            errors.country && (
                              <p className="error">
                                {errors.country &&
                                  touched.country &&
                                  errors.country}
                              </p>
                            )}
                        </div>
                        <div>
                          <TextFieldInput
                            select
                            label="Governorate"
                            newValue={values?.gov}
                            placeholder={"Choose governorate"}
                            name={"gov"}
                            change={handleChange}
                            blur={handleBlur}
                            fullWidth
                            className={`Input`}
                            variant="outlined"
                            SelectProps={{
                              displayEmpty: true,
                            }}
                          >
                            <MenuItem value="" className="" disabled>
                              Choose Governorate
                            </MenuItem>
                            {cities?.map((item) => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </TextFieldInput>
                          {errors.gov && touched.gov && errors.gov && (
                            <p className="error">
                              {errors.gov && touched.gov && errors.gov}
                            </p>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div>
                      <TextFieldInput
                        select
                        label="Where did you hear about us?"
                        newValue={values.referralSource}
                        placeholder={"Select an option or enter your answer..."}
                        name={"referralSource"}
                        change={handleChange}
                        blur={handleBlur}
                        fullWidth
                        className={`Input`}
                        variant="outlined"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem value="" className="" disabled>
                          Where did you hear about us?
                        </MenuItem>
                        {[
                          {
                            name: "Social Media (Facebook, Instagram, LinkedIn, etc.)",
                            value: "Social Media",
                          },
                          {
                            name: "Search Engine (Google, Bing, etc.)",
                            value: "Search Engine",
                          },
                          {
                            name: "Friend/Colleague Recommendation",
                            value: "Friend",
                          },
                          {
                            name: "Attended an Event or Workshop",
                            value: "Event",
                          },
                          {
                            name: "Advertisement (Online or Offline)",
                            value: "Advertisement",
                          },
                          {
                            name: "Other",
                            value: "Other",
                          },
                        ]?.map((item) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </TextFieldInput>
                      {errors.referralSource &&
                        touched.referralSource &&
                        errors.referralSource && (
                          <p className="error">
                            {errors.referralSource &&
                              touched.referralSource &&
                              errors.referralSource}
                          </p>
                        )}
                    </div>
                    <div>
                      <TextFieldInput
                        name="promoCode"
                        Required={false}
                        type="text"
                        placeholder={"Enter your promocode"}
                        className="Input"
                        label={"Promocode"}
                        change={(e) => {
                          setPromocode(e.target.value);
                        }}
                        blur={handleBlur}
                        newValue={promocode}
                      />
                      {errors.promoCode &&
                        touched.promoCode &&
                        errors.promoCode && (
                          <p className="error">
                            {errors.promoCode &&
                              touched.promoCode &&
                              errors.promoCode}
                          </p>
                        )}
                    </div>
                    {/* <div>
                      <TextFieldInput
                        select
                        label="Admin Role"
                        newValue={values.admin_role}
                        placeholder={"Choose your role"}
                        name={"admin_role"}
                        change={handleChange}
                        blur={handleBlur}
                        fullWidth
                        className={`Input`}
                        variant="outlined"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem value="" className="" disabled>
                          Choose Admin Role
                        </MenuItem>
                        {roles?.map((item) => (
                          <MenuItem key={item?.id} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </TextFieldInput>
                      {errors.admin_role &&
                        touched.admin_role &&
                        errors.admin_role && (
                          <p className="error">
                            {errors.admin_role &&
                              touched.admin_role &&
                              errors.admin_role}
                          </p>
                        )}
                    </div> */}
                  </div>
                  {userType == "organization" ? (
                    <>
                      <h2>Organization Details:</h2>
                      <div className="FormContainerBox">
                        <div>
                          <TextFieldInput
                            select
                            label="Choose your organization"
                            newValue={values.organization_id}
                            placeholder={"Choose your organization"}
                            name={"organization_id"}
                            change={handleChange}
                            blur={handleBlur}
                            fullWidth
                            className={`Input`}
                            variant="outlined"
                            SelectProps={{
                              displayEmpty: true,
                            }}
                          >
                            <MenuItem value="" className="" disabled>
                              Choose your organization
                            </MenuItem>
                            {organizationsList?.map((item) => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                            <MenuItem value={-1} className="">
                              Other
                            </MenuItem>
                          </TextFieldInput>
                          {errors.organization_id &&
                            touched.organization_id &&
                            errors.organization_id && (
                              <p className="error">
                                {errors.organization_id &&
                                  touched.organization_id &&
                                  errors.organization_id}
                              </p>
                            )}
                        </div>
                        {values.organization_id == -1 ? (
                          <>
                            <div>
                              <TextFieldInput
                                name="organization_name"
                                type="text"
                                placeholder={"Enter organization name"}
                                className="Input"
                                label="Organization Name"
                                change={handleChange}
                                blur={handleBlur}
                                newValue={values.organization_name}
                              />
                              {errors.organization_name &&
                                touched.organization_name &&
                                errors.organization_name && (
                                  <p className="error">
                                    {errors.organization_name &&
                                      touched.organization_name &&
                                      errors.organization_name}
                                  </p>
                                )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div>
                          <TextFieldInput
                            select
                            label="Branch Country"
                            newValue={values.country}
                            placeholder={"Choose branch country"}
                            name={"country"}
                            change={(newValue) => {
                              handleChange(newValue);
                              setCountry(newValue);
                              console.log(newValue);
                            }}
                            blur={handleBlur}
                            fullWidth
                            className={`Input`}
                            variant="outlined"
                            SelectProps={{
                              displayEmpty: true,
                            }}
                          >
                            <MenuItem value="" className="" disabled>
                              Choose Branch Country
                            </MenuItem>
                            {countriesList?.map((item) => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </TextFieldInput>
                          {errors.country &&
                            touched.country &&
                            errors.country && (
                              <p className="error">
                                {errors.country &&
                                  touched.country &&
                                  errors.country}
                              </p>
                            )}
                        </div>
                        <div>
                          <TextFieldInput
                            select
                            label="Branch Governorate"
                            newValue={values.gov}
                            placeholder={"Choose Branch governorate"}
                            name={"gov"}
                            change={handleChange}
                            blur={handleBlur}
                            fullWidth
                            className={`Input`}
                            variant="outlined"
                            SelectProps={{
                              displayEmpty: true,
                            }}
                          >
                            <MenuItem value="" className="" disabled>
                              Choose Branch Governorate
                            </MenuItem>
                            {cities?.map((item) => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </TextFieldInput>
                          {errors.gov && touched.gov && errors.gov && (
                            <p className="error">
                              {errors.gov && touched.gov && errors.gov}
                            </p>
                          )}
                        </div>
                        <div style={{ position: "relative" }}>
                          <label className="labelPhone">
                            Branch Phone Number*
                          </label>
                          <PhoneInput
                            label={"Enter Branch Phone Number"}
                            className="textfield Input phoneInput"
                            value={PhoneOrg}
                            defaultCountry="eg"
                            onChange={(e) => {
                              setPhoneOrg(e);
                            }}
                          />
                          {PhoneOrgError && PhoneOrgTouched ? (
                            <p className="error">{PhoneOrgError}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          <TextFieldInput
                            name="address"
                            type="text"
                            placeholder={"Enter branch address"}
                            className="Input"
                            label="Branch Address"
                            change={handleChange}
                            blur={handleBlur}
                            newValue={values.address}
                          />
                          {errors.address &&
                            touched.address &&
                            errors.address && (
                              <p className="error">
                                {errors.address &&
                                  touched.address &&
                                  errors.address}
                              </p>
                            )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {/* <div className="FlexHeader">
                    <div>
                      <h2>Section 3 - Teams</h2>
                      <p>Click Add team to add a new team..</p>
                      {teamsError ? <p className="error">{teamsError}</p> : ""}
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setTeams([
                          ...teams,
                          {
                            team_number: teams.length + 1,
                            team_category: "",
                          },
                        ]);
                        setTeamsError(null);
                      }}
                    >
                      Add Team
                    </Button>
                  </div>
                  {teams?.length ? (
                    <div className="teamsCont">
                      {teams?.map((team) => (
                        <TeamCard
                          key={team?.number}
                          categories={categoriesList}
                          teams={teams}
                          team={team}
                          setTeams={setTeams}
                        />
                      ))}
                    </div>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        marginBottom: "30px",
                        marginTop: "5px",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      Add At least one team..
                    </p>
                  )} */}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "40px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "200px" }}
                      onClick={() => {
                        setPhoneTouched(true);
                        setPhoneOrgTouched(true);
                        handleSubmit();
                      }}
                    >
                      {addLoading ? (
                        <div className="loadingSmallBox">
                          <PulseLoader size={6} color="#fff" />
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>

            <WhiteModal
              open={confirmOpen}
              handleClose={() => {
                setConfirmOpen(false);
              }}
              Style={"modalBox"}
              inSide={
                <Box>
                  <h1 style={{ textAlign: "center", marginBottom: "5px" }}>
                    Application Review
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "30px",
                      marginTop: "5px",
                    }}
                  >
                    Please review all details carefully before submission
                  </p>
                  {userType != "organization" ? (
                    // <h3>1 - Admin:</h3>
                    <h3>Team Leader:</h3>
                  ) : (
                    ""
                  )}

                  <ul className="confirmList">
                    <li>
                      <span className="listTitle">Name:</span>{" "}
                      {newObjectConfirm?.account?.admin?.name}
                    </li>
                    <li>
                      <span className="listTitle">Email:</span>{" "}
                      {newObjectConfirm?.account?.admin?.email}
                    </li>
                    <li>
                      <span className="listTitle">Phone Number:</span>{" "}
                      {newObjectConfirm?.account?.admin?.contactNumber}
                    </li>
                    {newObjectConfirm?.account?.type == "personal" ? (
                      <>
                        <li>
                          <span className="listTitle">Country:</span>{" "}
                          {
                            newObjectConfirm?.account?.personalAccountInfo
                              ?.country
                          }
                        </li>
                        <li>
                          <span className="listTitle">Governate:</span>{" "}
                          {newObjectConfirm?.account?.personalAccountInfo?.city}
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                  {newObjectConfirm?.account?.type == "organization" ? (
                    <>
                      <h3>Organization Details:</h3>
                      <ul className="confirmList">
                        <li>
                          <span className="listTitle">Name:</span>{" "}
                          {newObjectConfirm?.account?.organization?.name}
                        </li>
                        <li>
                          <span className="listTitle">Phone Number:</span>{" "}
                          {
                            newObjectConfirm?.account?.organization?.branch
                              ?.contactNumber
                          }
                        </li>
                        <li>
                          <span className="listTitle">Country:</span>{" "}
                          {
                            newObjectConfirm?.account?.organization?.branch
                              ?.country
                          }
                        </li>
                        <li>
                          <span className="listTitle">Governate:</span>{" "}
                          {
                            newObjectConfirm?.account?.organization?.branch
                              ?.city
                          }
                        </li>
                        <li>
                          <span className="listTitle">Address:</span>{" "}
                          {
                            newObjectConfirm?.account?.organization?.branch
                              ?.address
                          }
                        </li>
                      </ul>
                    </>
                  ) : (
                    ""
                  )}

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px 0",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "150px", margin: "0 5px" }}
                      onClick={() => {
                        setFinalSave(true);
                      }}
                    >
                      {FinalLoading ? (
                        <div className="loadingSmallBox">
                          <PulseLoader size={6} color="#fff" />
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "150px", margin: "0 5px" }}
                      onClick={() => {
                        setConfirmOpen(false);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </Box>
              }
            />
            <WhiteModal
              open={feesOpen}
              handleClose={() => {
                setFeesOpen(false);
              }}
              Style={"modalBox"}
              inSide={
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <CloseIcon
                      style={{ cursor: "pointer", marginTop: "5px" }}
                      onClick={() => {
                        setFeesOpen(false);
                      }}
                    />
                  </Box>
                  <h2 style={{ textAlign: "center", margin: "10px 5px 5px 0" }}>
                    Code Challenge Championship
                  </h2>
                  <Box
                    sx={{
                      height: "3px",
                      width: "100%",
                      backgroundColor: "primary.main",
                      margin: "10px auto",
                    }}
                  />
                  <h3>Competition Tracks:</h3>
                  <ul>
                    <li>KidCoder Challenge</li>
                    <li>Web Challenge</li>
                    <li>Python Challenge</li>
                    <li>AI Challenge</li>
                  </ul>
                  <h3>Team Rules:</h3>
                  <ul>
                    <li>From 1 - 3 members guided by a teacher/coach </li>
                  </ul>
                  {/* <h3>Registration Fees:</h3>
                  <ul>
                    <li>1,400 EGP or $30 per team member</li>
                  </ul> */}
                  <h3>
                    The deadline of registration is{" "}
                    <span style={{ color: "red" }}>01 - 08 - 2024.</span>
                  </h3>
                </>
              }
            />
          </Box>
        </>
      )}
    </>
  );
};

export default FirstStageTemplate;
