import React, { useEffect } from "react";
import TrainersAccTemplate from "../../Templetes/TrainersAcc/TrainersAcc.template";

const TrainersAccPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - Trainers Accreditation";
  }, []);
  return <TrainersAccTemplate />;
};

export default TrainersAccPage;
