import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  MenuItem,
  Typography,
  Grid,
} from "@mui/material";
// import Grid from "@mui/system/Grid";
import React, { useEffect, useState } from "react";
import { Images } from "../../helpers/Images";
import { useNavigate } from "react-router-dom";
import CategoriesCarousel from "../../Components/organisms/Home/CategoriesCarousel/CategoriesCarousel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./OrganizationAcc.css";
import TextFieldInput from "../../Components/atoms/Inputs/TextField/TextFieldInput";
import { useForm } from "@formspree/react";
import useFetch from "../../Hooks/useFetch";
import { countriesURL } from "../../Hooks/URL";
import { toast } from "react-toastify";
import TextArea from "../../Components/atoms/TextArea/TextArea";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import TaskIcon from "@mui/icons-material/Task";
import StarsIcon from "@mui/icons-material/Stars";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import QuizIcon from "@mui/icons-material/Quiz";
import MergeIcon from "@mui/icons-material/Merge";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Facebook, Instagram } from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import HandshakeIcon from "@mui/icons-material/Handshake";
import EventIcon from "@mui/icons-material/Event";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LaptopIcon from "@mui/icons-material/Laptop";
import InstagramIcon from "@mui/icons-material/Instagram";
import PlaceIcon from "@mui/icons-material/Place";
import { margin } from "@mui/system";

const OrganizationAccTemplate = () => {
  const navigate = useNavigate();
  const [state, handleSubmit] = useForm("mnnjdgqw");
  const [Name, setName] = useState("");
  const [Organization, setOrganization] = useState("");
  const [Country, setCountry] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Type, setType] = useState("Organization");

  const { data: countriesList, loading: countriesLoading } =
    useFetch(countriesURL);

  useEffect(() => {
    if (state.succeeded) {
      setName("");
      setOrganization("");
      setEmail("");
      setCountry("");
      setPhone("");
      setType("Organization");
      toast.success(
        "Thank you for filling the form! We  will get back to you as soon as possible.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: { backgroundColor: "#6e1010" },
        }
      );
    }
  }, [state.succeeded]);

  const Organizations = [
    {
      photo: Images.mawaheb,
      name: "Mawaheb Academy",
      whatsapp: "https://wa.me/+201090234005",
      linkedin: "https://www.linkedin.com/company/mawaheb-academy",
      email: "",
      brief:
        "Mawaheb Academy is a dedicated STEM education academy with 10+ years of experience in Robotics, Coding, and AI, delivering high-quality training that fosters creativity and innovation.",
      location: "Suez /Cairo /Maadi /Elshorouk - Egypt",
      facebook: "https://www.facebook.com/MawahebAcademy1",
      Instagram: "https://www.instagram.com/mawahebacademy1/",
      mapLocation: "https://maps.app.goo.gl/mrLtFCCxXprrLHau6",
    },
    {
      photo: Images.purpose,
      name: "Purpose Academy",
      whatsapp: "https://wa.me/+970594958002",
      linkedin: "https://www.linkedin.com/company/purposeedu/",
      email: "info@purpose.ps",
      brief:
        "Purpose is an AI & robotics training provider. Our goal is to train talent in Palestine and worldwide in preparation for the fourth industrial revolution and the AI future.",
      location: "Ramallah - Plastine",
      facebook: "https://www.facebook.com/PurposeEdu",
      Instagram: "https://www.instagram.com/purpose_edu",
      website: "",
      mapLocation: "",
    },
    {
      photo: Images.techware,
      name: "Techware Academy",
      whatsapp: "https://wa.me/+201010179604",
      linkedin: "https://www.linkedin.com/company/techware-academy/",
      email: "techwareacademy@gmail.com",
      brief:
        "Techware Academy, founded in 2018, is a leading STEM education center in 10th of Ramadan, offering robotics, coding, electronics, and web design courses for students aged 4-18, fostering innovation and global competitiveness.",
      location: "Sharqiya",
      facebook: "https://www.facebook.com/techware.academy/",
      Instagram: "",
      mapLocation: "https://maps.app.goo.gl/k5rGgaVjzkLsLFHG8",
    },
    {
      photo: Images.logic,
      name: "Logic Club",
      whatsapp: "https://wa.me/+201000108161",
      linkedin: "https://www.linkedin.com/company/37503774",
      email: "logiclub.egypt@gmail.com",
      brief:
        "Logic Club, founded in 2011, teaches kids technology through robotics, coding, and electronics, fostering creativity, problem-solving, teamwork, and critical thinking while emphasizing moral values and character development.",
      location: "Cairo",
      facebook: "https://www.facebook.com/LogiClub",
      Instagram: "",
      mapLocation: "https://goo.gl/maps/EgHEh3TrDAsRPN579",
    },
    {
      photo: Images.tamayoz,
      name: "TAMAYOZ Academy ",
      whatsapp: "https://wa.me/+201500987687",
      linkedin: "",
      email: "tamayoz.academy00@gmail.com",
      brief:
        "Ready to take your child's education to the next level? \u2728\n Our robotics course provides a fun and engaging learning environment. Discover the benefits of STEM education today with TAMAYOZ Academy \u2764\ufe0f",
      location: "Cairo",
      facebook: "https://www.facebook.com/share/1B3QLopQ2K/",
      Instagram: "",
      mapLocation: "https://maps.app.goo.gl/JrvmT82Vew4d9pwo8 ",
    },
    {
      photo: Images.mindCraft,
      name: "Mind Craft Academy",
      whatsapp: "https://wa.me/+201155490240",
      linkedin: "",
      email: "mindcraft433@gmail.com",
      brief:
        "MIND CRAFT Academy is a dedicated STEM education organization with 4+ years of experience in Robotics, Coding, and AI, delivering high-quality trainings for students aged from 6-16 years.\nCertified from stem.org and Arab robotics and AI Association \n\nLocation:\u00a0El Mahalla El kubra - Egypt\n\n\n",
      location: "Gharbiya",
      facebook:
        "https://www.facebook.com/profile.php?id=100089128407841&mibextid=ZbWKwL",
      Instagram: "",
      mapLocation: "https://maps.app.goo.gl/QadQq7E8ER12fYTQA",
    },
    {
      photo: Images.light,
      name: "Light Academy",
      whatsapp: "https://wa.me/+201005082653",
      linkedin: "https://www.linkedin.com/in/light-academy-4688a32b4",
      email: "lightstemaccreditation990@gmail.com",
      brief:
        "Light Academy is dedicated STEM education organization with 1+ years of experience in Robotics, Coding, and AI,UC math, delivering high-quality trainings for students aged from 6-16 years.\nLocation: Menof/Menofia - Egypt\n",
      location: "Menufiya",
      facebook: "https://www.facebook.com/share/1E28uwCz3U/",
      Instagram: "",
      mapLocation: "https://maps.app.goo.gl/kY2UQb44BeQFr74X8?g_st=iwb",
    },
    {
      photo: Images.little,
      name: "Little Coder Academy",
      whatsapp: "https://wa.me/+201124872494",
      linkedin: "https://www.linkedin.com/in/little-coder-academy-890237294/",
      email: "little.coder.ac@gmail.com",
      brief:
        "Little Coder Academy is a dedicated STEM education organization with 8+ years of experience in Robotics, Coding, and AI, delivering high-quality trainings for students aged from 6-16 years.\nLocation: Giza/Beni-suef - Egypt",
      location: "Beni Suef",
      facebook: "https://www.facebook.com/littlecoderacademy",
      Instagram: "",
      mapLocation: "https://maps.app.goo.gl/DpgCcMguUVqREz978",
    },
    {
      photo: Images.inventa,
      name: "INVENTA",
      whatsapp: "https://wa.me/+201040737208",
      linkedin: "",
      email: "academyinventa@gmail.com",
      brief:
        "Inventa Academy, we empower young minds through Robotics, Coding, and AI, turning kids into real-life inventors! Our expert-led courses provide hands-on experience, problem-solving skills, and creativity for students aged 6 to 17.\nLocation: Alexandria - Egypt ",
      location: "Alexandria",
      facebook: "https://www.facebook.com/share/165bH73xdo/",
      Instagram: "",
      mapLocation: "https://maps.app.goo.gl/E7KCX1hWwj2xDQuG7",
    },
    {
      photo: Images.technology,
      name: "Technology Leaders Academy",
      whatsapp: "https://wa.me/+01012500134",
      linkedin: "",
      email: "mr.eslam.h.elmogy@gmail.com",
      brief:
        "Technology Leaders Academy 11+ years of experience is a STEM education center teaching kids (4-18) electronics, programming, and robotics, Coding, and AI. It trains students for national and international competitions, fostering innovation and hands-on learning.\nLocations: shikh-zayed/6-october/nasr-city/elmohandseen/elfardos",
      location: "Cairo",
      facebook: "https://www.facebook.com/TechnologyLeadersZayed/",
      Instagram: "",
      mapLocation: "https://www.google.com/maps?q=30.0406238,31.0108664",
    },
    {
      photo: Images.willy,
      name: "Willy Robotics Academy",
      whatsapp: "https://wa.me/+201007368867",
      linkedin: "",
      email: "janarafat18@gmail.com",
      brief:
        "Willy Robotics Academy is dedicated STEM education organisation with +5 years of experience in Robotics and ,Coding and AI ,delivering high quality trainings for students aged from 6-18 years. \nLocation: 285 L Pyramids garden-Giza.",
      location: "Giza",
      facebook: "https://www.facebook.com/share/19z5Z64Nip/",
      Instagram: "",
      mapLocation: "https://maps.app.goo.gl/rUXjoLRZCEDCDocR9",
    },
    {
      photo: Images.innovation,
      name: "Innovation-hub",
      whatsapp: "https://wa.me/+201124338883",
      linkedin: "",
      email: "eng.hader2012@gmail.com",
      brief:
        "Innovation-hub focuses on training kids in programming and robotics using a STEM-based approach. It equips young learners with technical skills, problem-solving abilities, and creativity through hands-on experiences, fostering innovation and future-ready skills.",
      location: "Cairo",
      facebook: "https://www.facebook.com/share/1A6z5DmAky/?mibextid=wwXIfr",
      Instagram: "",
      mapLocation: "",
      website: "https://innovation-hub.app/",
    },
    {
      photo: Images.innova,
      name: "Innova STEM Education",
      whatsapp: "https://wa.me/+201060867751",
      linkedin: "https://www.linkedin.com/company/innovastemedu/",
      email: "omarkhaled2k3@gmail.com",
      brief:
        "Innova STEM Education is a leading STEM academy with 10+ years of experience in programming and robotics, offering courses in AI, web development, Java, Python, app development, game development, and more for students aged 6-18. Our students excel in national and international competitions.\nLocation: Alexandria, Egypt",
      location: "Alexandria",
      facebook: "https://www.facebook.com/INNOVA.STEM.edu",
      Instagram: "",
      mapLocation: "https://maps.app.goo.gl/ZcJ1moaqSBLu3ziJA",
    },
    {
      photo: Images.lc,
      name: "I.C Robotics Academy",
      whatsapp: "https://wa.me/+201124032424",
      linkedin: "https://www.linkedin.com/company/ic-robotics-academy/",
      email: "eng.amr.saadawi@gmail.com",
      brief:
        "\u0623\u0643\u0627\u062f\u064a\u0645\u064a\u0629 \u0627\u0644\u0631\u0648\u0628\u0648\u062a \u0648\u0627\u0644\u0628\u0631\u0645\u062c\u0629 I.C Robotics Academy \u0627\u0644\u0631\u0627\u0626\u062f\u0629 \u0641\u064a \u0627\u0644\u062a\u0639\u0644\u064a\u0645 \u0648\u0627\u0644\u062a\u0635\u0646\u064a\u0639 \u0627\u0644\u062a\u0643\u0646\u0648\u0644\u0648\u062c\u064a \u0628\u0645\u062f\u064a\u0646\u0629 \u0627\u0644\u0645\u0646\u0635\u0648\u0631\u0629 \u0645\u0646\u0630 \u0639\u0627\u0645 \u0662\u0660\u0661\u0667\n\n\u062a\u0633\u0639\u0649 \u0627\u0644\u0623\u0643\u0627\u062f\u064a\u0645\u064a\u0629 \u062f\u0627\u0626\u0645\u064b\u0627 \u0644\u062a\u0623\u0647\u064a\u0644 \u0627\u0644\u0623\u0637\u0641\u0627\u0644 \u0648\u0627\u0644\u0634\u0628\u0627\u0628 \u0645\u0646 \u0633\u0646 5 \u0625\u0644\u0649 17 \u0633\u0646\u0629 \u0645\u0646 \u062e\u0644\u0627\u0644 \u0628\u0631\u0627\u0645\u062c STEAM \u0627\u0644\u0645\u062a\u0643\u0627\u0645\u0644\u0629..\n",
      location: "Dakhaliya",
      facebook: "https://www.facebook.com/share/194Kt8XZMz/?mibextid=wwXIfr",
      Instagram: "",
      mapLocation:
        "https://maps.app.goo.gl/hhVH8nc6o65eFqC88?g_st=com.google.maps.preview.copy",
    },
    {
      photo: Images.land,
      name: "Tricksland Steam Academy",
      whatsapp: "https://wa.me/+201113723845",
      linkedin: "https://www.linkedin.com/in/alaa-ahmed-8996ab19a",
      email: "alaafcieng@gmail.com",
      brief:
        "\ud83c\udf1fWhat is Tricks Land ?\nTricks land is the First Biggest Kids-Land To Unlock the Future \ud83c\udf0f\u2728\nTricksLand Training Students aged 4 to 16 years old \nOn Robotics \ud83e\uddbe\ud83e\udd16 , Programming, Electronics \nin Bani-Suef government \n\n\ud83c\udf1fFounded by: Steam Academy 2017\n\n\ud83c\udf1fOur Vision:\nLearning is a journey and powerful weapon which you can use to change the world \ud83c\udf0e \n",
      location: "Beni Suef",
      facebook: "https://www.facebook.com/share/1DaECpKRAn/?mibextid=wwXIfr",
      Instagram: "",
      mapLocation:
        "https://maps.app.goo.gl/BcKcDCU7G9eWyUQz8?g_st=com.google.maps.preview.copy",
    },
    {
      photo: Images.techno,
      name: "Techno Genius",
      whatsapp: "https://wa.me/+201044329178",
      linkedin: "",
      email: "samermohammed941@gmail.com",
      brief:
        "TechnoGenius Academy is an educational institution specializing in technology and innovation. Their curriculum includes courses on robotics, automation, artificial intelligence, electronics and circuit design, and the Internet of Things (IoT). The academy aims to prepare future generations by equipping them with essential skills in these cutting-edge fields. ",
      location: "Dakhaliya",
      facebook: "https://www.facebook.com/share/18gTWebLvb/",
      Instagram: "",
      mapLocation: "https://maps.app.goo.gl/Abyuqktms3bn5meU6?g_st=ac",
    },
    {
      photo: Images.kids,
      name: "Science Kids",
      whatsapp: "https://wa.me/+201111649609",
      linkedin: "",
      email: "aya.hesham602.th6@gmail.com",
      brief:
        "Science Kids is a dedicated STEM education organization with 3+ years of experience in Robotics, Coding, and AI, delivering high-quality trainings (online and offline)for students aged from 5-16 years.\nLocation:Mit Ghamr, Dakhliya,Egypt ",
      location: "Dakhaliya",
      facebook: "https://www.facebook.com/ScienceClubMG",
      Instagram: "",
      mapLocation: "https://maps.google.com/?q=30.724319,31.258963",
    },
    {
      photo: Images.sts,
      name: "Smart Techno Students",
      whatsapp: "https://wa.me/+201204633338",
      linkedin: "",
      email: "eman.stseg@gmail.com",
      brief:
        "STS Academy is a dedicated STEM education organization with 10+ years of experience in Robotics, Coding, and AI, delivering high-quality trainings for students aged from 4-20 years.\nLocation: Obour city cairo Egypt ",
      location: "Cairo",
      facebook: "https://www.facebook.com/share/1ERNbNtHuX/?mibextid=wwXIfr",
      Instagram: "",
      mapLocation:
        "https://maps.app.goo.gl/f6aC1x43TzqgDQwq7?g_st=com.google.maps.preview.copy",
    },
    {
      photo: Images.child,
      name: "Child Home Robotics Academy ",
      whatsapp: "https://wa.me/+201065402123",
      linkedin: "",
      email: "child.home.robotics@gmail.com",
      brief:
        "Child Home Robotics Academy with 9+ years of experience empowering young minds with the skills they need to succeed in the digital age. Our engaging, hands-on coding programs turn screen time into productive learning\u2014helping children develop critical thinking, creativity, and problem-solving skills.",
      location: "Qena",
      facebook: "https://www.facebook.com/share/1DC4CESk7C/",
      Instagram: "",
      mapLocation: "https://goo.gl/maps/TcQQE9xcTLAivdaK8",
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "20px 20px",
            sm: "20px 60px",
            lg: "30px 120px",
            xl: "75px 120px",
          },
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${Images.back4})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "50px" },
            fontWeight: "800",
            marginTop: "5px",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          Become An Accredited Organization
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <a href="#form">
            <Button
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "16px",
                marginTop: "0",
                fontWeight: "600",
                backgroundColor: "secondary.main",
                color: "primary.main",
              }}
            >
              Apply for Accreditation
            </Button>
          </a>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h5"}
          sx={{
            textAlign: { xs: "center", md: "start" },
            fontSize: { xs: "25px", sm: "34px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Driving Innovation Through Education
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "150px", md: "350px" },
            width: "100%",
            backgroundColor: "primary.light",
            margin: { xs: "5px auto 0", md: "5px 0 0 0" },
          }}
        />
        <br />
        <Typography
          variant={"h3"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "16px", sm: "18px" },
            fontWeight: "400",
            color: "primary.darker",
          }}
        >
          The Organization Accreditation program celebrates institutions and
          training centers that deliver outstanding coding education.
          Accreditation positions your organization as a trusted leader in
          fostering the next generation of tech talent.
        </Typography>

        <Typography
          id={"part"}
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "22px", sm: "35px" },
            fontWeight: "500",
            color: "primary.darker",
            marginTop: "40px",
          }}
        >
          Meet Our Accredited Organizations
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: { xs: "150px", sm: "300px" },
            backgroundColor: "primary.light",
            margin: "5px auto 30px",
          }}
        />
        <Grid
          container
          rowSpacing={{ xs: 4, md: 6 }}
          columnSpacing={{ xs: 2, md: 4 }}
        >
          {Organizations?.map((item) => (
            <Grid item xs={6} md={3}>
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                  height: { xs: "150px", md: "200px" },
                  backgroundSize: "70%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url("${item?.photo}")`,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    "&:hover": {
                      opacity: "1",
                    },
                  }}
                >
                  {/* <Typography
                    variant={"h4"}
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "500",
                      color: "White",
                      marginTop: "10px",
                    }}
                  >
                    About {item?.name}
                  </Typography> */}

                  <Typography
                    variant={"p"}
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "9px", sm: "12px" },
                      fontWeight: "500",
                      color: "White",
                      marginTop: "10px",
                    }}
                  >
                    {item?.brief}
                  </Typography>
                  <Typography
                    variant={"p"}
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "10px", sm: "14px" },
                      fontWeight: "500",
                      color: "White",
                      marginTop: "10px",
                    }}
                  >
                    Location: {item?.location}
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant={"h4"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "16px", sm: "22px" },
                  fontWeight: "500",
                  color: "primary.darker",
                  marginTop: "10px",
                }}
              >
                {item?.name}
              </Typography>
              {/* <Typography
                variant={"h5"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "300",
                  color: "primary.darker",
                }}
              >
                Senior Accredited Trainer
              </Typography> */}
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex !important",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: { xs: "0 auto", md: "5px auto 0" },
                }}
              >
                {item?.facebook ? (
                  <a target="_blank" href={item?.facebook} className="accIcon">
                    <Facebook className="accInsideIcon" />
                  </a>
                ) : (
                  ""
                )}
                {item?.whatsapp ? (
                  <a target="_blank" href={item?.whatsapp} className="accIcon">
                    <WhatsAppIcon className="accInsideIcon" />
                  </a>
                ) : (
                  ""
                )}
                {item?.linkedin ? (
                  <a target="_blank" href={item?.linkedin} className="accIcon">
                    <LinkedInIcon className="accInsideIcon" />
                  </a>
                ) : (
                  ""
                )}
                {item?.instagram ? (
                  <a target="_blank" href={item?.instagram} className="accIcon">
                    <InstagramIcon className="accInsideIcon" />
                  </a>
                ) : (
                  ""
                )}
                {/* {item?.email ? (
                  <a
                    target="_blank"
                    href={`mailto:${item?.email}`}
                    className="accIcon"
                  >
                    <EmailIcon className="accInsideIcon" />
                  </a>
                ) : (
                  ""
                )} */}
                {item?.mapLocation ? (
                  <a
                    href={item?.mapLocation}
                    target="_blank"
                    className="accIcon"
                    style={{ margin: "0" }}
                  >
                    <PlaceIcon className="accInsideIcon" />
                  </a>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            color: "primary.darker",
            fontSize: "30px",
            marginTop: "60px",
          }}
        >
          Why Become an Accredited Organization?
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: "150px",
            backgroundColor: "primary.light",
            margin: "10px auto",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            margin: "20px auto 20px",
            maxWidth: "1500px",
            textAlign: "20px",
          }}
        >
          <ul className="WhyList WhyListBig">
            <li>
              <LaptopIcon className="listTick" />
              Establish your organization as a recognized hub for coding
              excellence and innovation in education.
            </li>
            <li>
              <HistoryEduIcon className="listTick" />
              Demonstrate your commitment to maintaining the highest standards
              in technology and coding education.
            </li>
            <li>
              <HandshakeIcon className="listTick" />
              Gain recognition by being featured as an accredited partner on our
              official platform and network.
            </li>
            <li>
              <EventIcon className="listTick" />
              Collaborate with Code Challenge on large-scale initiatives,
              events, and industry-leading educational programs.
            </li>
          </ul>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px",
            sm: "50px 60px",
            lg: "50px 120px",
            xl: "150px 120px",
          },
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(${Images.services})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "30px", sm: "40px" },
            fontWeight: "800",
            marginTop: "5px",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          Shaping the Future of Coding Education
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "16px", sm: "20px" },
            fontWeight: "500",
            maxWidth: "1100px",
            margin: "0 auto",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          Becoming an accredited organization reinforces your commitment to
          excellence, positioning you as a trusted leader in coding education
          while opening doors to collaborations, growth opportunities, and a
          wider impact in the tech community.
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <a href="#form">
            <Button
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "16px",
                marginTop: "0",
                fontWeight: "600",
                backgroundColor: "secondary.main",
                color: "primary.main",
              }}
            >
              Apply for Accreditation
            </Button>
          </a>
        </Box>
      </Box>
      {/* <CategoriesCarousel /> */}
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px 40px",
            sm: "50px 60px 50px",
            lg: "50px 120px 50px",
          },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            color: "primary.darker",
            fontSize: "30px",
          }}
        >
          Benefits of Accreditation
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: "150px",
            backgroundColor: "primary.light",
            margin: "6px auto",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            margin: "20px auto 60px",
            maxWidth: "1500px",
            textAlign: "20px",
          }}
        >
          <ul className="WhyList WhyListQuar">
            <li>
              <VerifiedIcon className="listTick" />
              Certified accreditation and logo for marketing and website
              recognition
            </li>
            <li>
              <VerifiedIcon className="listTick" />
              Inclusion in our Accredited Organizations directory for visibility
            </li>
            <li>
              <VerifiedIcon className="listTick" />
              Priority partnership opportunities with Code Challenge initiatives
            </li>
            <li>
              <VerifiedIcon className="listTick" />
              Access to dedicated events and camps for networking and growth
            </li>
          </ul>
        </Box>
        <Typography
          id={"part"}
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "30px", sm: "35px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Apply for Accreditation
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: { xs: "150px", sm: "200px" },
            backgroundColor: "primary.light",
            margin: "5px auto 10px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            width: "100%",
            maxWidth: "750px",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "30px",
          }}
        >
          Ready to take your impact to the next level? Apply for Code Challenge
          Accreditation and become a beacon of innovation and learning in your
          community!
        </Typography>
        <form
          onSubmit={handleSubmit}
          id="form"
          style={{ maxWidth: "800px", margin: "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
              columnGap: "20px",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="name"
                  type="text"
                  placeholder={"Enter your full name.."}
                  className="Input"
                  label="Full Name"
                  id={"input"}
                  newValue={Name}
                  change={(e) => setName(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="organization"
                  type="text"
                  placeholder={"Enter organization name.."}
                  className="Input"
                  label="Organization Name"
                  id={"input"}
                  newValue={Organization}
                  change={(e) => setOrganization(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="email"
                  type="text"
                  placeholder={"Enter your email.."}
                  className="Input"
                  label="Email"
                  newValue={Email}
                  change={(e) => setEmail(e.target.value)}
                />
              </div>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="number"
                  type="text"
                  placeholder={"Enter your contact number.."}
                  className="Input"
                  label="Contact Number"
                  newValue={Phone}
                  change={(e) => setPhone(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  select
                  label="Country"
                  placeholder={"Choose your country"}
                  name={"country"}
                  fullWidth
                  className={`Input`}
                  variant="outlined"
                  newValue={Country}
                  change={(e) => setCountry(e.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="" disabled>
                    Choose Country
                  </MenuItem>
                  {countriesList?.data?.map((item, i) => (
                    <MenuItem key={i} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </TextFieldInput>
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  select
                  label="Accreditation For"
                  name={"type"}
                  fullWidth
                  className={`Input`}
                  variant="outlined"
                  newValue={Type}
                  change={(e) => setType(e.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="" disabled>
                    Choose Accreditation Type
                  </MenuItem>
                  <MenuItem key={1} value={"Trainer"}>
                    Trainer
                  </MenuItem>
                  <MenuItem key={2} value={"Organization"}>
                    Organization
                  </MenuItem>
                </TextFieldInput>
              </div>
            </Box>
          </Box>
          {/* <div style={{ margin: "10px auto 10px" }}>
            <TextArea
              Required
              name="text"
              placeholder={"Any comments.."}
              className="Input"
              label="Comments/Queries"
              newValue={Message}
              change={(e) => setMessage(e.target.value)}
            />
          </div> */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              disabled={state.submitting}
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "14px",
                marginTop: "10px",
                fontWeight: "600",
                backgroundColor: "primary.main",
                color: "secondary.main",
              }}
              // onClick={() => navigate("/firststage")}
            >
              Apply Now
            </Button>
          </div>
        </form>
      </Box>
    </>
  );
};

export default OrganizationAccTemplate;
