import React, { useEffect } from "react";
import OrganizationAccTemplate from "../../Templetes/OrganizationAcc/OrganizationAcc.template";

const OrganizationAccPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - Organization Accreditation";
  }, []);
  return <OrganizationAccTemplate />;
};

export default OrganizationAccPage;
