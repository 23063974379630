import React, { useEffect, useState } from "react";
import useSave from "../../../Hooks/useSave";
import {
  applicationURL,
  countriesURL,
  categoriesURL,
} from "../../../Hooks/URL";
import useFetch from "../../../Hooks/useFetch";
import { Fetch } from "../../../Hooks/Fetch";
import FirstStageConfirmTemplate from "../../../Templetes/Registration/FirstStageConfirm/FirstStageConfirm.template";
import { useLocation, useNavigate, useParams } from "react-router";

const FirstStageConfirmPage = () => {
  // const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // const [application, setApplication] = useState({});

  // const {
  //   data: applicationData,
  //   loading: applicationLoading,
  //   error: applicationError,
  // } = useFetch(`${applicationURL}/check/${id}`);

  // useEffect(() => {
  //   if (!applicationLoading && !applicationError && applicationData) {
  //     setApplication(applicationData.data);
  //   }
  // }, [applicationData, applicationLoading, applicationError]);

  useEffect(() => {
    document.title = "Code Challenge - First Stage Application";
  }, []);

  useEffect(() => {
    if (!location?.state) navigate("/firststage");
  }, [location]);

  return (
    <FirstStageConfirmTemplate application={location?.state?.application} />
  );
};

export default FirstStageConfirmPage;
