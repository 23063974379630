import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Images } from "../../helpers/Images";
import emailjs from "@emailjs/browser";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Groups2Icon from "@mui/icons-material/Groups2";
import EmailIcon from "@mui/icons-material/Email";

const ContactTemplate = () => {
  // const location = useLocation();
  // console.log();
  // const [state, setState] = useState({
  //   fname: "",
  //   lname: "",
  //   email: "",
  //   message: "",
  //   mailSent: false,
  //   error: null,
  // });
  // const API_PATH = `${window.location.origin}/API/index.php`;

  // const handleFormSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(state);
  //   axios({
  //     method: "post",
  //     url: `${API_PATH}`,
  //     headers: { "content-type": "application/json" },
  //     data: state,
  //   })
  //     .then((result) => {
  //       setState({
  //         mailSent: result.data.sent,
  //       });
  //     })
  //     .catch((error) => setState({ error: error.message }));
  // };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "55px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Get In Touch
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "150px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "auto",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "400",
            color: "primary.darker",
            marginTop: "20px",
          }}
        >
          Want to get in touch? We'd love to hear from you. Here's how you can
          reach us..
          <br />
          <span
            style={{
              fontSize: "17px",
              wordBreak: "break-all",
            }}
          >
            Email: info@codechallenge-championship.org - Phone: +201500333864
          </span>
          <br />
          <span
            style={{
              fontSize: "17px",
              wordBreak: "break-all",
            }}
          >
            Address: 127 Mostafa El Nahaas St. Nasr City - Cairo - Egypt
          </span>
        </Typography>
        <Grid
          container
          spacing={5}
          sx={{ marginTop: "30px", justifyContent: "space-around" }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: { xs: "10px", md: "30px" },
                backgroundColor: "primary.main",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                alt="call"
                src={Images.call}
                style={{
                  width: "70px",
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  marginTop: "5px",
                  marginBottom: "20px",
                }}
              />
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "secondary.main",
                }}
              >
                Talk?
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "300",
                  color: "secondary.main",
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                Interested to participate in Code Challenge Championship this
                year? Just pick up the phone to call a member of our team..
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <a
                  href={"tel:+201500333864"}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "primary.main",
                    }}
                  >
                    Call now
                  </Button>
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: { xs: "10px", md: "30px" },
                backgroundColor: "primary.main",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <img
                alt="chat"
                src={Images.chat}
                style={{
                  width: "80px",
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
              />
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "secondary.main",
                }}
              >
                Chat?
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "300",
                  color: "secondary.main",
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                Have any question? <br />
                Just click here to immediately chat with us..
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=%2B201500333864&fbclid=IwAR2Q0ES6pK05-OcugrLDDzFvd5qdd6SrzjAz07-6vnp_FUFVhxwE5pCxTXE"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "primary.main",
                    }}
                  >
                    Chat now
                  </Button>
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: { xs: "10px", md: "30px" },
                backgroundColor: "primary.main",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Groups2Icon
                style={{
                  width: "80px",
                  marginTop: "5px",
                  marginBottom: "10px",
                  fontSize: "100px",
                  color: "white",
                }}
              />
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "secondary.main",
                }}
              >
                Join Our Community!
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "300",
                  color: "secondary.main",
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                All updates will be shared exclusively on our WhatsApp group.
                Let's connect and grow together!
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <a
                  target="_blank"
                  href="https://chat.whatsapp.com/IrT8paSZspG75r3VRMCGFH"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "primary.main",
                    }}
                  >
                    Join now
                  </Button>
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: { xs: "10px", md: "30px" },
                backgroundColor: "primary.main",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <EmailIcon
                style={{
                  width: "80px",
                  marginTop: "5px",
                  marginBottom: "10px",
                  fontSize: "100px",
                  color: "white",
                }}
              />
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "secondary.main",
                }}
              >
                Any Inquiries?
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "300",
                  color: "secondary.main",
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                For any inquiries or to connect via email, please reach out to
                us at:
                <br />
                info@codechallenge-championship.org
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <a
                  target="_blank"
                  href="mailto:info@codechallenge-championship.org"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "primary.main",
                    }}
                  >
                    Send mail
                  </Button>
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        id={"questions"}
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "20px" }}>
          {[
            {
              question: "What's Code Challenge Championship?",
              answer:
                "It's an annual coding and programming competition designed to test their coding skills and creativity.",
            },
            {
              question: "Who can participate in Code Challenge Championship?",
              answer:
                "Students aged from 5 to 24 years are welcomed to particpate in the available tracks.",
            },
            {
              question: "What are the available tracks?",
              answer:
                "In 2025, we have 4 main Tracks as follows: (KidCoder Challenge, Web Challenge, Python Challenge, AI Challenge)",
            },
            {
              question: "How the competition look like?",
              answer:
                "There will be 3 challenges for each category with ascending difficulty. Teams have 45 minutes to finish each challenge.",
            },
            {
              question: "What's the team structure for participation?",
              answer:
                "The team should have 1-3 members guided by a coach/teacher.",
            },
          ].map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <strong>{item.question}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ContactTemplate;
