import React, { useEffect, useState } from "react";
import FirstStageTemplate from "../../../Templetes/Registration/FirstStage/FirstStage.template";
import useSave from "../../../Hooks/useSave";
import {
  applicationURL,
  countriesURL,
  categoriesURL,
  organizationsURL,
  firstStageURL,
} from "../../../Hooks/URL";
import useFetch from "../../../Hooks/useFetch";
import { Fetch } from "../../../Hooks/Fetch";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const FirstStagePage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [promocode, setPromocode] = useState();
  const [country, setCountry] = useState({ target: { value: 42 } });
  const [cities, setCities] = useState([]);
  const [newObject, setNewObject] = useState({});
  const [newObjectConfirm, setNewObjectConfirm] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [feesOpen, setFeesOpen] = useState(false);
  const [goSave, setGoSave] = useState(false);
  const [FinalSave, setFinalSave] = useState(false);
  const [userType, setuserType] = useState("organization");

  useEffect(() => {
    setPromocode(searchParams.get("promocode"));
  }, [searchParams]);

  const handleType = (event, newType) => {
    setuserType(newType);
  };

  const { data: countriesList, loading: countriesLoading } =
    useFetch(countriesURL);
  const { data: organizationsList, loading: organizationsLoading } =
    useFetch(organizationsURL);
  // const { data: categoriesList, loading: categoriesLoading } =
  //   useFetch(categoriesURL);

  const { saveState, loading: addLoading } = useSave({
    url: `${firstStageURL}/confirmation`,
    goSave,
    setGoSave,
    newObject,
    popup: false,
  });

  const { saveState: finalState, loading: FinalLoading } = useSave({
    url: `${firstStageURL}`,
    goSave: FinalSave,
    setGoSave: setFinalSave,
    newObject,
  });

  useEffect(() => {
    if (country)
      Fetch(`${countriesURL}/${country?.target?.value}/cities`, setCities);
  }, [country]);

  useEffect(() => {
    console.log(newObjectConfirm);
    if (newObjectConfirm && Object.keys(newObjectConfirm)?.length)
      setConfirmOpen(true);
  }, [newObjectConfirm]);

  useEffect(() => {
    if (!addLoading && saveState?.dataRes?.data) {
      setNewObjectConfirm(saveState?.dataRes?.data);
    }
  }, [addLoading, goSave, saveState]);

  useEffect(() => {
    if (finalState?.dataRes?.data && !FinalLoading) {
      setConfirmOpen(false);
      navigate(`/firststage/registered`, {
        state: { application: finalState?.dataRes?.data?.data },
      });
    }
  }, [FinalLoading, finalState]);

  useEffect(() => {
    document.title = "Code Challenge - First Stage Application";
  }, []);

  return (
    <FirstStageTemplate
      userType={userType}
      handleType={handleType}
      newObject={newObject}
      setNewObject={setNewObject}
      setGoSave={setGoSave}
      setFinalSave={setFinalSave}
      confirmOpen={confirmOpen}
      setConfirmOpen={setConfirmOpen}
      addLoading={addLoading}
      countriesList={countriesList?.data ?? []}
      organizationsList={organizationsList?.data ?? []}
      // categoriesList={categoriesList?.data ?? []}
      newObjectConfirm={newObjectConfirm?.data}
      setCountry={setCountry}
      cities={cities}
      FinalLoading={FinalLoading}
      feesOpen={feesOpen}
      setFeesOpen={setFeesOpen}
      promocode={promocode}
      setPromocode={setPromocode}
    />
  );
};

export default FirstStagePage;
