import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../../helpers/Images";
import { categoriesData } from "../../../../helpers/Arrays";
import { Gallery, GalleryArray } from "../../../../helpers/gallery";

const KidTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        {false ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "70px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "100px auto 0 auto",
              }}
            >
              COMING SOON
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "100px", md: "300px" },
                width: "100%",
                backgroundColor: "primary.main",
                margin: "5px auto 5px auto",
              }}
            />
            <Typography
              variant={"h2"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "40px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "5px auto 100px auto",
              }}
            >
              Code Challenge
            </Typography>
          </Box>
        ) : (
          <Grid
            container
            spacing={4}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <Typography
                variant={"h1"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "40px", sm: "55px" },
                  fontWeight: "500",
                  color: "#010101",
                }}
              >
                KidCoder Challenge
              </Typography>
              <Box
                sx={{
                  height: "3px",
                  maxWidth: { xs: "100px", md: "150px" },
                  width: "100%",
                  backgroundColor: "#6a0505",
                  margin: "10px auto",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1fJNm6vlMX8SpXNVQWo3RZ5P2m-DJx-PH"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                    }}
                  >
                    Download General Rules (PDF)
                  </Button>
                </a>
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=127ETh3IH0Doz4PH3whhKdLw3hIpD0pCA"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                    }}
                  >
                    Download KidCoder Rules (PDF)
                  </Button>
                </a>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1QD5CC0l2u2UQJ5ceG8S_A1qwwj1wLFZY"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                    }}
                  >
                    Download KidCoder 2024 Scoring Sheet
                  </Button>
                </a>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: "17px",
                    margin: "5px 0",
                    fontWeight: "600",
                    backgroundColor: "#6a0505",
                  }}
                  onClick={() => navigate("/firststage")}
                >
                  Register Now
                </Button>
              </Box>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "primary.darker",
                  // maxWidth: "750px",
                  margin: "25px 0 10px 0",
                  fontSize: "17px",
                }}
              >
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "30px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "50px",
                    marginBottom: "10px",
                  }}
                >
                  Brief:
                </Typography>
                KidCoder Challenge is an engaging coding competition allocated
                for young students aged 5 to 13 years old. It provides a
                platform for children to showcase their coding skills in a fun
                and educational environment. Participants are encouraged to
                unleash their creativity and problem-solving abilities through
                various exciting coding challenges, so join us in its 4th
                edition and be one of the winners this year!
                <br />
                <br />
                In KidCoder challenge, there are{" "}
                <strong>three main categories and three main rounds</strong> in
                each category with different challenges. Each participant/team
                should finish the challenge and submit their work in specified
                time !..
                <br />
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "20px",
                  }}
                >
                  Level 1 Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#6a0505",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#6a0505" }}>
                  Age:
                </span>{" "}
                5 - 7
                <br />
                <span style={{ fontWeight: "700", color: "#6a0505" }}>
                  Team size:
                </span>{" "}
                1-3 people guided by a coach <br />
                <span style={{ fontWeight: "700", color: "#6a0505" }}>
                  Software:
                </span>{" "}
                Scratch junior
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1ztEm5OlBcCRnkztV_vvTBTL029T4gSnz"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 1 - Competition Mission 1 (2024)
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1sakgFHQnuwboqFTiGkn-oBaDiwnYqm3d"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 1 - Competition Mission 2 (2024)
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1Lklzme5kCrKkvlgk27E-Jf9W-d65AfCF"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 1 - Competition Mission 3 (2024)
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1s16dqFQIwKM_Q_OPoPAUpRnFaWdhunIv"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    Download KidCoder Level 1 Sample
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1A1vBxB4PCz3lH_r9OyctkNSjiZWk6M-l"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 1 sample Answer
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=13kqZdHF4iuLVTrXyy8X1dULNZhzOjsct"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    Download Kidcoder Level 1 Handbook
                  </Button>
                </a>
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "40px",
                  }}
                >
                  Level 2 Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#6a0505",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#6a0505" }}>
                  Age:
                </span>{" "}
                8 - 10
                <br />
                <span style={{ fontWeight: "700", color: "#6a0505" }}>
                  Team size:
                </span>{" "}
                1-3 people guided by a coach
                <br />
                <span style={{ fontWeight: "700", color: "#6a0505" }}>
                  Software:
                </span>{" "}
                Scratch 3 or PictoBlox
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1h-YSXPBnC5giHhVbM5dkVDKzI5uE1i5b"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 2 - Competition Mission 1 (2024)
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1_4u8rjlSttmVlr8QsR21HQz1BNk6zUKi"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 2 - Competition Mission 2 (2024)
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=10Jmp6YD-yjCmSCU-LT3gPsP2BmmXfmCe"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 2 - Competition Mission 3 (2024)
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1ok9hyGTcuRqSZpVMADAcLCIfK3R1Hef7?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 2 - Competition Mission 1 (2023)
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1VanMSJ9Aj8tr9q8QvA3bs0rEID0WUCHp?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 2 - Competition Mission 2 (2023)
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1ROJWvw9U5V9-7vnzQUQLnPyi9pNbQPFC"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    Download KidCoder Level 2 Sample 1
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=10YG-3BEwq4etkcyJ2NbwhTk9rxQrf41C"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    Download KidCoder Level 2 sample 1 Answer
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1DJKGXBwxBBwFAoLCTiAwgcSlNcAYXNWL"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    Download KidCoder Level 2 Sample 2
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1nSmHN3zKDYJMnWEVTsM7pRfxQPn7rYZ_"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    Download KidCoder Level 2 sample 2 Answer
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1cOCtPPdDhjMDVwol40pcx-IDHgq2zeBV"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    Download Kidcoder Level 2 Handbook
                  </Button>
                </a>
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "40px",
                  }}
                >
                  level 3 Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#6a0505",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#6a0505" }}>
                  Age:
                </span>{" "}
                11 - 13
                <br />
                <span style={{ fontWeight: "700", color: "#6a0505" }}>
                  Team size:
                </span>{" "}
                1-3 people guided by a coach
                <br />
                <span style={{ fontWeight: "700", color: "#6a0505" }}>
                  Software:
                </span>{" "}
                Scratch 3 or PictoBlox
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=16TJ8pHl5ivubsCRSYnUa241BP_C15Mio"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 3 - Competition Mission 1 (2024)
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1JgVpQtghKgvAmLrmyCMZlpvllcFibAUl"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 3 - Competition Mission 2 (2024)
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=11CPMWrSMIaAfOwOvEqZ4ugZvmWeWNbix"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 3 - Competition Mission 3 (2024)
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1UAXe7IKOym0bwhykN3C3M9C3VmKYmxqa?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    KidCoder Level 3 - Competition Mission (2023)
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=12-TirUjC0FXT2XsHUoiYQZApIcPgDhZ_"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    Download KidCoder Level 3 Sample 1
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1XFpA12bk6sWzjsLD29INIYFbBhT5kLWT"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    Download KidCoder Level 3 sample 1 Answer
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1RkzxWRa0dRD6cdBEsW3dwD1mn2u4bmMR"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#6a0505",
                      padding: "3px 10px",
                    }}
                  >
                    Download Kidcoder Level 3 Handbook
                  </Button>
                </a>
                <br />
              </Typography>
              <br />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              sx={{
                marginTop: "20px",
                justifyContent: "center",
                marginLeft: "16px",
                marginBottom: "20px",
              }}
            >
              {GalleryArray?.sort((a, b) => 0.5 - Math.random())
                ?.slice(0, 7)
                ?.map((item, i) => (
                  <Grid item xs={12} sm={6} md={3} key={i}>
                    <img
                      loading={"lazy"}
                      alt={i}
                      src={item}
                      style={{ width: "100%", borderRadius: "10px" }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "40px" },
              fontWeight: "500",
              color: "secondary.main",
            }}
          >
            Our All Tracks
          </Typography>
          <Box
            sx={{
              height: "3px",
              maxWidth: { xs: "100px", md: "150px" },
              width: "100%",
              backgroundColor: "secondary.main",
              margin: "10px auto 0 auto",
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "50px", justifyContent: "center" }}
          >
            {categoriesData
              ?.filter((category) => category.title !== "KidCoder")
              ?.map((item) => (
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: { xs: "200px", sm: "250px" },
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        textAlign: "center",
                        fontSize: "35px",
                        fontWeight: "500",
                        color: "secondary.main",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        height: "3px",
                        maxWidth: "90px",
                        width: "100%",
                        backgroundColor: "secondary.main",
                        margin: "5px auto 5px auto",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "primary.main",
                          width: "fit-content",
                          margin: { xs: "5px 0 20px 0", sm: "10px 0 40px 0" },
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        view more
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default KidTemplate;
