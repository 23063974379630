import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import "./Ranking.css";
import { academiesData } from "../../../../helpers/Arrays";
import { ChampionsImages } from "../../../../helpers/gallery";
import { useNavigate } from "react-router-dom";

const Ranking = () => {
  const navigate = useNavigate();
  const createData = (rank, name, gold, silver, bronze) => {
    return { rank, name, gold, silver, bronze };
  };

  const rows = [
    createData(1, "Treasures Academy", 4, 2, 1),
    createData(2, "ETS Academy", 4, 1, "-"),
    createData(3, "Mawaheb Academy", 1, 1, 3),
    createData(4, "Smartpeek Academy", 1, 1, 1),
    createData(5, "Robotix Academy", "-", 2, "-"),
    createData(6, "Big Hero Egyptian Robotics Academy", "-", 1, 1),
    createData(7, "Innova STEM Education Academy", "-", 1, 1),
    createData(8, "Jupiter Academy", "-", "-", 2),
  ];
  return (
    <Box
      sx={{
        width: "100%",
        padding: {
          xs: "50px 20px 20px 20px",
          sm: "50px 60px",
          lg: "70px 120px",
        },
        backgroundColor: "secondary.main",
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "500",
          color: "primary.darker",
          marginBottom: "5px",
        }}
      >
        Code Challenge 2024 Champions
      </Typography>
      {/* <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.main",
          margin: "auto",
        }}
      /> */}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontSize: "16px",
            margin: "10px 10px 0",
            fontWeight: "600",
            backgroundColor: "primary.main",
            color: "secondary.main",
          }}
          onClick={() => navigate("/firststage")}
        >
          Register Now
        </Button>
      </Box>
      <Grid
        container
        spacing={3}
        justifyContent={"center"}
        sx={{ marginTop: "20px" }}
      >
        {ChampionsImages?.sort((a, b) => 0.5 - Math.random())
          ?.slice(0, 6)
          ?.map((item, i) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={i}>
              <img
                loading={"lazy"}
                alt={i}
                src={item}
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Ranking;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
